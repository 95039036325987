
import moment from "moment";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "DatePicker",
})
export default class DatePicker extends Vue {
  @Prop() value!: string;
  @Prop({ type: Function, default: () => {} }) onSelect!: any;
  @Prop({
    type: Function,
    default: (date: any) =>
      moment(date, "YYYY-MM-DD").format("ddd").toUpperCase(),
  })
  weekdayFormat!: string;
  @Prop({ type: Boolean }) showAdjacentMonths!: boolean;
  @Prop({ type: Boolean }) range!: boolean;
  @Prop({ type: String }) min!: string;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;

  @Emit("input")
  public update(date: string) {
    this.onSelect && this.onSelect();
    return date;
  }
}
