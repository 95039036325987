
import { Component, Vue } from 'vue-property-decorator';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import AdSpacesPlaylist from '@/pages/regulator/Playlist/components/AdSpacesPlaylist/AdSpacesPlaylist.vue';

@Component({
  name: "Playlist",
  components: { AdSpacesPlaylist, PageTitle, IButton  },
})
export default class Playlist extends Vue {
}
