
import { isRegExp } from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TextField',
})
export default class TextField extends Vue {
  @Prop({ type: String }) label!: string;
  @Prop({ type: String }) placeholder!: string;
  @Prop({ type: Boolean, default: false }) persistentPlaceholder!: boolean;
  @Prop({ type: String }) type!: string;
  @Prop({ type: String }) hint!: string;
  @Prop({ type: Boolean, default: false }) persistentHint!: boolean;
  @Prop({ type: Boolean, default: false }) fullWidth!: boolean;
  @Prop({ type: String }) appendIcon!: string;
  @Prop({ type: String }) prependLabel!: string;
  @Prop({ type: String }) prefix!: string;
  @Prop({ type: String }) suffix!: string;
  @Prop() value!: string;
  @Prop({ type: String }) maxlength!: string;
  @Prop({ type: Boolean }) required!: boolean;
  @Prop({ type: RegExp }) allowedPattern!: RegExp;
  @Prop({ type: Array }) rules!: [(val: any) => boolean | string]
  @Prop({ type: String, default: '*' }) charReplacement!: string;
  @Prop({ type: String }) mask!: string;
  @Prop({ type: Boolean }) readonly!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;

  @Emit('input')
  public update(value: string) {
    return value;
  }

  @Emit('keypress')
  public keypress(event: any) {
    const { value } = event.target;
    const pressedChar = String.fromCharCode(event.keyCode);

    if (this.maxlength && value.length >= this.maxlength) {
      event.preventDefault();
    }

    if (
      this.allowedPattern &&
      isRegExp(this.allowedPattern) &&
      !this.allowedPattern.test(pressedChar)
    ) {
      event.preventDefault();
    }

    return event;
  }

  @Emit('click:append')
  public clickAppend() {
    return true;
  }

  public isPath(icon: string = '') {
    return icon.includes('/');
  }

  public hasListener(listenerName: string) {
    return Boolean(this.$listeners[listenerName]);
  }
}
