
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
  name: "Card",
})
export default class Card extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean;
}
