
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LocationData } from '@/types/locations';

@Component({
  name: "AdSpaceSelectItem",
  components: {},
})
export default class AdSpaceSelectItem extends Vue {
  @Prop({ required: true }) public adSpace!: LocationData;
}
